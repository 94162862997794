<template>
  <div
    class="fill-width"
    :style="{ height: clientHeight, backgroundColor: '#dae0e5' }"
  >
    <v-container class="fill-height login">
      <v-card
        flat
        class="justify-center shadow align-center rounded-lg"
        width="60%"
      >
        <v-card
          class="shadow radius-card login-card mt-12 rounded-lg"
          width="100%"
          height="600"
        >
          <v-row class="fill-height fill-width login-card-row rounded-lg">
            <v-col
              sm="12"
              md="7"
              lg="7"
              class="login-card-row-left fill-height fill-width rounded display-flex align-center justify-center"
            >
              <v-row>
                <v-col
                  sm="12"
                  md="12"
                  lg="12"
                  class="display-flex justify-center"
                >
                  <div>
                    <v-icon size="60" color="white">mdi-account</v-icon>
                  </div>
                </v-col>
                <v-col
                  sm="12"
                  md="12"
                  lg="12"
                  class="display-flex justify-center title"
                >
                  Yuesai Admin
                </v-col>
                <v-col sm="12" md="12" lg="12">
                  <v-form
                    ref="loginForm"
                    v-model="validated"
                    lazy-validation
                    class="fill-width"
                  >
                    <v-text-field
                      v-model="form.username"
                      filled
                      dense
                      single-line
                      label="请输入账号"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.password"
                      filled
                      dense
                      single-line
                      label="请输入密码"
                      type="password"
                    ></v-text-field>
                    <v-btn color="primary" block dark @click="handleLogin"
                      >登 录</v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="12" md="5" lg="5" class="login-card-right"></v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      validated: false,
      form: {
        username: "",
        password: ""
      }
    };
  },

  computed: {
    ...mapGetters("common", {
      clientHeight: "getClientHeight"
    })
  },

  methods: {
    handleLogin() {
      this.login(this.form);
    },

    ...mapActions("user", ["login"])
  }
};
</script>

<style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  &-card {
    background-size: cover;
    background-position: center;
    background-image: url("https://picsum.photos/700");
    &-row {
      margin-left: 0;
      margin-right: 0;
      &-left {
        background-color: white;
      }
    }
  }
}
</style>
